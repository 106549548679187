// Gatsby supports TypeScript natively!
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    {
      mobileAndUpHeaderImage: file(relativePath: {eq: "contact-us@3x.png"}) {
        childImageSharp {
          fixed(width: 133, height: 118, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tabletAndUpHeaderImage: file(relativePath: {eq: "contact-us@3x.png"}) {
        childImageSharp {
          fixed(width: 221, height: 196, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Thank you - Contact Us" />
      <header className="page-header">
        <div className="header-image-container tablet-and-up">
          <Image fixed={data.tabletAndUpHeaderImage.childImageSharp.fixed} />
        </div>
        <div className="header__inner-container">
          <h1>Contact Us</h1>
          <div>
            <p>Whether you have question about features, trials, pricing, need a demo or anything else, our team is ready to answer all your questions.</p>
          </div>
          <Image className="mobile-to-tablet" fixed={data.mobileAndUpHeaderImage.childImageSharp.fixed} />
        </div>
      </header>
      <section className="content highlighted centered">
        <h2 className="flush-button">Thank you!</h2>
        <p>We got your message. We’ll get in contact with you as soon as possible from our email <a href="mailto:info@prasthana.com">info@prasthana.com</a></p>
        <Link className="mt-4 button primary on-light-background" to="/contact-us">Send Another</Link>
        <section className="extra-padding">
        </section>
        <h2 className="flush-button">Our Location</h2>
        <p>1-58/5/A, 3rd Floor, Above Bank of India, Opp.Furniture City, Gachibowli X roads, Hyderabad, Telangana - 500031.</p>
        <section>
        </section>
      </section>
      <section className="flush-bottom">
        <iframe className="map" src="https://maps.google.com/maps?q=Prasthana%20Software&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" style={{border:0}} aria-hidden="false" tabIndex={0}></iframe>
      </section>
    </Layout>
  )
}


export default ContactUs